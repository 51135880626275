.content-container{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    gap:10px;
}
.content-container>ol>li{
    text-align: left;
    width: 100%;
    margin-top: 4px;
}